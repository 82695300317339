export const pagesWithNoBookDemo = [
    '/sign-up',
    '/brf',
    '/speedledger',
    '/payment',
    '/webbyra',
    '/book-demo',
    '/categories/bokningssystem-for-smarta-verkstader'
];

export const pagesWithNoChat = [
    '/payment'
]

const poll = (time: number) => {
    return new Promise((resolve) => {
        const intervalId = setInterval(() => {
            if(window.BOKAMERA && typeof window !== 'undefined' && 'restartEmbedBookingApp' in window) {
                window.restartEmbedBookingApp();
                resolve(true);
                clearInterval(intervalId);
            } else if (!window.BOKAMERA) {
                resolve(true);
            }
        }, time);
        
    })
}

export const embedWidget = (configuration: any) => {
    poll(50).then(() => {
        window.BOKAMERA = { configuration };
        
        const script = document.createElement('script');
        script.src = import.meta.env.VITE_EMBED_BOOKING_SCRIPT_SRC;
        document.head.appendChild(script);
    });    
}